import './App.css';
import Mint from './app/Mint';
import Navbar from './app/Navbar';
import Roadmap from './app/Roadmap';
import RooCouncil from './app/RooCouncil';
import Team from './app/Team';
import Appfooter from './app/Appfooter';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
        <Navbar />
        <Mint />
        <Roadmap />
        <RooCouncil />
        <Team />
        <Appfooter />
    </ThemeProvider>
  );
}

export default App;
