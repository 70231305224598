import { useMoralis } from "react-moralis";
import { useTotalSupply } from '../hooks/useTotalSupply';

function Counter() {
    const {Moralis, isAuthenticated, account} = useMoralis();
    const {totalSupply, setTotalSupply, fetchTotalSupply} = useTotalSupply();

    if (!isAuthenticated || !account) {
        return (
            <div className=""></div>
        );
    }
    else {
      fetchTotalSupply();
    }

    return (
        <div className="App-count">{totalSupply} / 7777</div>
    );
}

export default Counter;
