import Bub from '../media/Bub.png';
import '../App.css';

function RooCouncil() {

  return (
    <div className="Yellow-wrapper">
      <div className="Container">
        <div className="grid">
          <div className="circle boxfront border-4 border-[#e59520]"></div>
          <img className ="drop-shadow-md box1" src={Bub} alt="" />
          <span className="drop-shadow-md box2 text-5xl italic text-white">Only x26</span>
        </div>
        <p className="box3 uppercase border-4 drop-shadow-lg border-[#e59520] font-bold text-center text-2xl mt-4 mb-4 bg-[#145747] pb-5 py-4 px-5 rounded-lg text-[#fef3b3]">The Roo Counsel consists of notable golf partners to the KCC,
          as well as any member who owns a Roo with the Gold Jacket Trait.
          The council will have voting rights when it comes to partnerships,
          tournament details, and other directions of the community. Gold Jacket
          members will have additional perks such as exclusive gear, early access to event sign ups,
          Gold Member only giveaways and more.
        </p>
      </div>
    </div>
  );
}

export default RooCouncil;
