import '../App.css';

function Appfooter() {

  return (
    <footer className="App-footer">
      <p>©2022 KCC. All Rights Reserved.</p>
    </footer>
  );
}

export default Appfooter;
