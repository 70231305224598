import exampleOne from "../media/1.png";
import exampleTwo from "../media/2.png";
import exampleThree from "../media/3.png";
import exampleFour from "../media/4.png";
import exampleFive from "../media/5.png";
import exampleSix from "../media/6.png";
import '../App.css';

function Team() {
  return (
    <div className="Lilac-wrapper" id="Team">
          <div className="Grid">
            <h2 className="Team text-center text-6xl text-slate-50 mb-6">The Team</h2>
            <div className="Grid-inner Container text-center text-xl text-slate-50">
              <figure>
              <img src={exampleOne} alt="example 1" className="rounded"/>
              <br></br>
              <figcaption className="text-cyan-300">JPhilly<br></br>(Superintendent)</figcaption>
              </figure>
              <figure>
              <img src={exampleTwo} alt="example 2" className="rounded"/>
              <br></br>
              <figcaption className="text-cyan-300">ZPhilly<br></br>(Club President)</figcaption>
              </figure>
              <figure>
              <img src={exampleThree} alt="example 3" className="rounded"/>
              <br></br>
              <figcaption className="text-cyan-300">JSquared<br></br>(General Manager)</figcaption>
              </figure>
              <figure>
              <img src={exampleFour} alt="example 4" className="rounded"/>
              <br></br>
              <figcaption className="text-orange-400">MadaRednaxela<br></br>(Moderator)</figcaption>
              </figure>
              <figure>
              <img src={exampleFive} alt="example 5" className="rounded"/>
              <br></br>
              <figcaption className="text-orange-400">RisBrian<br></br>(Club Artist)</figcaption>
              </figure>
              <figure>
              <img src={exampleSix} alt="example 6" className="rounded"/>
              <br></br>
              <figcaption className="text-orange-400">JBLosman<br></br>(Moderator)</figcaption>
              </figure>
            </div>
          </div>
        </div>
  );
}

export default Team;

