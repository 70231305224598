import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import {useMoralis} from 'react-moralis';
import {getExplorer} from '../networks';
import Address from '../Address/Address';

interface AccountDialogProps extends DialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AccountDialog(props: AccountDialogProps) {
  const {account, chainId, logout} = useMoralis();
  const {open, setOpen} = props;

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Account</DialogTitle>
      <DialogContent sx={{p: 1.875}}>
        <Card sx={{mt: 1.25, borderRadius: '1rem'}}>
          <CardContent sx={{p: 1.875}}>
            <Address avatar="left" size={6} copyable sx={{fontSize: '20px'}} />
          </CardContent>
          <CardActions>
            <Button
              href={`${getExplorer(chainId!)}address/${account}`}
              target="_blank"
              rel="noreferrer"
              startIcon={<LaunchOutlinedIcon />}
              sx={{textTransform: 'none', borderRadius: 99}}
            >
              View on Explorer
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          onClick={async () => {
            await logout();
            window.localStorage.removeItem('connectorId');
            setOpen(false);
          }}
          sx={{width: '100%', borderRadius: 3, textTransform: 'none'}}
        >
          Disconnect Wallet
        </Button>
      </DialogActions>
    </Dialog>
  );
}
