import sign2 from '../media/Sign2.png';
import roadmapPDF from '../media/roadmapPDF.pdf';
import '../App.css';

function Roadmap() {

  return (
      <div className="Pink-wrapper"  id="Roadmap">
        <div className="Container flexbox">
              <h2 className="text-slate-700 font-bold mb-7 text-6xl">Welcome Kangaroo Country Club!</h2>
              <br />
              <p className="welcomesign text-white uppercase font-bold text-lg bg-[#568167] border-8 border-[#b2b2b2] rounded-2xl p-2 drop-shadow-md">
                Minting a "Roo" on Feb. 17th (or buying on the secondary market) grants you
                an exclusive membership to The Kangaroo Country Club. The goal of the
                Kangaroo Country Club is to provide our digital members with the following
                through the community wallet:
              </p>
              <br></br>
              <a href={roadmapPDF} target="_blank"><img src={sign2} alt=""/></a>
          </div>
        </div>
  );
}

export default Roadmap;
