import MintNFT from '../components/Contracts/MintNFT';
import Counter from '../components/Counter';
import sign from '../media/Sign.png';
import '../App.css';

function Apptop() {

  return (
      <div className="App-top" id="Home">
        <div className="App-date text-slate-700 text-center font-bold">
          <h3>Mint is live!</h3>
        </div>

        <Counter />
        <MintNFT />
        <div className="welcome">
            <img className="" src={sign} alt="Sign" />
        </div>
      </div>
  );
}

export default Apptop;
