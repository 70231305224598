import {useState} from 'react';

export const usePresaleSig = () => {
      const [presaleSig, setPresaleSig] = useState("");

      async function fetchSig(address: string) {
            const response = await fetch(`./api/verify/${address}`);
            const sig = await response.json();
            setPresaleSig(sig["signature"]);
      }
      return {presaleSig, fetchSig};
}

