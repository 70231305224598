import * as React from 'react';
import {useMoralis} from 'react-moralis';
import Button from '@mui/material/Button';
import {Theme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {getEllipsisTxt} from '../formatters';
import Blockie from '../Blockie';
import AccountDialog from './AccountDialog';
import ConnectWalletDialog from './ConnectWalletDialog';
import { CenterFocusStrong } from '@mui/icons-material';

function Account() {
  const {isAuthenticated, account} = useMoralis();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [authModalOpen, setAuthModalOpen] = React.useState(false);

  if (!isAuthenticated || !account) {
    return (
      <React.Fragment>
        <Button
          variant="contained"
          size="large"
          onClick={() => setAuthModalOpen(true)}
          sx={{
            height: 36,
            textTransform: 'none',
            py: 0,
            px: 1.75,
            borderRadius: 2,
          }}
        >
          Connect Wallet
        </Button>
        <ConnectWalletDialog open={authModalOpen} setOpen={setAuthModalOpen} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        size="large"
        onClick={() => setModalOpen(true)}
        sx={{
          height: 36,
          textTransform: 'none',
          py: 0,
          px: 1.75,
          borderRadius: 2,
          '&.MuiButton-contained': {
            backgroundColor: '#f4f4f4',
            color: '#000000',
          },
        }}
        endIcon={<Blockie currentWallet scale={3} />}
      >
        {getEllipsisTxt(account, 6)}
      </Button>
      <AccountDialog open={modalOpen} setOpen={setModalOpen} />
    </React.Fragment>
  );
}

export default Account;
