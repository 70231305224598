import Account from '../components/Account/Account';
import discord from "../media/discord.svg";
import twitter from "../media/twitter.svg";
import opensea from "../media/opensea.svg";
import '../App.css';

function Navbar() {
    return (
    <div className="App-header justify-between">
        <ul className="App-social mb-2">
          <li>
            <a
              href="https://discord.gg/xhjEmh9ZjH"
              target="_blank"
              rel="noreferrer"
            >
              <img className="drop-shadow-md" src={discord} alt="Discord" />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/TheKangarooCC"
              target="_blank"
              rel="noreferrer"
            >
              <img className="drop-shadow-md" src={twitter} alt="Twitter" />
            </a>
          </li>
          <li>
            <a
              href="https://opensea.io/collection/thekangaroocountryclub"
              target="_blank"
              rel="noreferrer"
            >
              <img className="drop-shadow-md" src={opensea} alt="OpenSea" />
            </a>
          </li>
        </ul>
        <div className="Clinks">
          <div className="links">
            <a href="#Home" className="text-slate-700 hover:text-zinc-100 ease-in-out duration-300">HOME</a>
            &nbsp;|&nbsp;
            <a href="#Roadmap" className="text-slate-700 hover:text-zinc-100 ease-in-out duration-300">ROADMAP</a>
            &nbsp;|&nbsp;
            <a href="#Team" className="text-slate-700 hover:text-zinc-100 ease-in-out duration-300">TEAM</a>
          </div>
          &nbsp;&nbsp;
          <Account />
        </div>
    </div>
    );
  }

export default Navbar;
