import {useState} from 'react';
import fetchContractValue from '../utils/fetchContractValue';

export const useTotalSupply = () => {
  const [totalSupply, setTotalSupply] = useState(0);

  function fetchTotalSupply() {
        fetchContractValue('totalSupply').then(value => {
        const result = Number(value);
        setTotalSupply(result);
        });
    }
    return {totalSupply, setTotalSupply, fetchTotalSupply};
};

