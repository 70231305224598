import {useState} from 'react';
import fetchContractValue from '../utils/fetchContractValue';

export const useMintMax = () => {
  const [presaleMintMax, setPresaleMintMax] = useState(0);
  const [publicSaleMintMax, setPublicSaleMintMax] = useState(0);

  function fetchMintMax(type: string) {
    if(type === 'presale') {
      fetchContractValue('maxPresaleMintPerAddress').then(value => {
        const result = Number(value);
        setPresaleMintMax(result);
      });
    }

    else if(type === 'public') {
      fetchContractValue('maxPublicMintPerAddress').then(value => {
        const result = Number(value);
        setPublicSaleMintMax(result);
      });
    }
  }

  return {presaleMintMax, publicSaleMintMax, fetchMintMax};
};

