import {useState} from 'react';
import fetchContractValue from '../utils/fetchContractValue';

export const useSaleState = () => {
  const [presaleActive, setPresaleActive] = useState(false);
  const [publicSaleActive, setPublicSaleActive] = useState(false);

  function saleStatus(type: string) {
    if(type === 'presale') {
      fetchContractValue('presaleActive').then(value => {
        setPresaleActive(Boolean(value));
      });
    }
    else if(type === 'public') {
      fetchContractValue('publicSaleActive').then(value => {
        setPublicSaleActive(Boolean(value));
      });
    }
  }

  return {presaleActive, publicSaleActive, saleStatus};
};

