import {useState} from "react";
import {useMoralis, useApiContract} from "react-moralis";

import {useSaleState} from "../../hooks/useSaleState";
import {useMintMax} from "../../hooks/useMintMax";
import {useTotalSupply} from "../../hooks/useTotalSupply";
import {useMint} from "../../hooks/useMint";
import {usePresaleSig} from "../../hooks/usePresaleSig";

import contractAbi from "../../utils/contract.json";

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

function MintNFT() {
  const {Moralis, isAuthenticated, account} = useMoralis();
  const {presaleActive, publicSaleActive, saleStatus} = useSaleState();
  const {presaleMintMax, publicSaleMintMax, fetchMintMax} = useMintMax();
  const {publicMint, presaleMint, isMinting} = useMint();
  const {presaleSig, fetchSig} = usePresaleSig();

  const [quantity, setQuantity] = useState(0);

  function increment() {
    if(quantity < 20) {
      setQuantity(quantity+1);
    }
  }

  function decrement() {
    if (quantity > 0) {
      setQuantity(quantity-1);
    }
  }

  function mintNFTs() {
    if(publicSaleActive) {
      publicMint(quantity);
    }
  }

  if (!isAuthenticated || !account) {
    return (
      <div className="MintStatus">Please Connect Wallet</div>
    );
  }
  else {
    saleStatus("public");
    fetchMintMax("public");
  }

  if(publicSaleActive) {
    if(isMinting) {
      return (<div className="MintStatus">Please wait, minting NFTs...</div>);
    }

    else {
      return (
      <div className="increment">
        <div className="v1">
          <button className="incrementbut" onClick={() => decrement()}>-</button>
            <div className="v2">{quantity}</div>
          <button className="incrementbut" onClick={() => increment()}>+</button>
        </div>
          <button className="MintButton" onClick={() => mintNFTs()}>Mint</button>
      </div>
      );
    }
  }

  else {
    return (<div className="MintStatus">Problem connecting to the blockchain, please try again later.</div>);
  }
}

export default MintNFT;
