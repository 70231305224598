import {DialogContentText} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {useMoralis} from 'react-moralis';
import {connectors} from './config';

interface ConnectWalletDialogProps extends DialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID
  ? parseInt(process.env.REACT_APP_CHAIN_ID)
  : 4;

export default function ConnectWalletDialog(props: ConnectWalletDialogProps) {
  const {authenticate} = useMoralis();
  const {open, setOpen} = props;

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1.25,
          fontWeight: 700,
          fontFamily: 'Concert One',
          fontSize: 25,
          letterSpacing: 1.5
        }}
      >
        Connect Wallet
      </DialogTitle>
      <DialogContent sx={{p: 1.875}}>
        <Grid container>
          {connectors.map(({title, icon, connectorId}) => (
            <Grid item xs={6} key={title}>
              <Button
                onClick={async () => {
                  try {
                    await authenticate({
                      provider: connectorId,
                      chainId: CHAIN_ID,
                    });
                    window.localStorage.setItem(
                      'connectorId',
                      connectorId || 'injected'
                    );
                    setOpen(false);
                  } catch (e) {
                    console.error(e);
                  }
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: 'auto',
                  mx: 'auto',
                  py: 2.5,
                  px: 0.625,
                  textTransform: 'none',
                }}
              >
                <Box
                  component="img"
                  src={icon}
                  alt={title}
                  sx={{
                    alignSelf: 'center',
                    fill: 'rgb(40, 13, 95)',
                    flexShrink: 0,
                    mb: 1,
                    height: 30,
                  }}
                />
                <DialogContentText sx={{fontSize: 17, fontWeight: 500}}>
                  {title}
                </DialogContentText>
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
